export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: "AIzaSyCnmMd4DFAi5beuNKn_KLKnl00vl7369cc",
  authDomain: "gjvrtour.firebaseapp.com",
  projectId: "gjvrtour",
  storageBucket: "gjvrtour.appspot.com",
  messagingSenderId: "610947020311",
  appId: "1:610947020311:web:a6e5c65ab2e9b963408e52",
  measurementId: "G-4WT6GWWPZZ",
};
