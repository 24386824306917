import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
export const cloudRoutes: Routes = [];
const routes: Routes = [
  {
    exact: true,
    path: "/ramadagwangju",
    component: lazy(() => import("./views/ramadagwangju")),
  },
  {
    exact: true,
    path: "/kdjcenter",
    component: lazy(() => import("./views/kdjcenter")),
  },
  {
    exact: true,
    path: "/higwangju",
    component: lazy(() => import("./views/higwangju")),
  },
  {
    exact: true,
    path: "/example/:id",
    component: lazy(() => import("./views/example")),
  },
  {
    exact: true,
    path: "/kdjcenter/simulation",
    component: lazy(() => import("./views/Edit")),
  },
  {
    exact: true,
    path: "/Tour/:id",
    component: lazy(() => import("./views/Tour")),
  },
];

export default routes;
